import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/our-team/hero';
import Commitment from '../components/our-team/commitment';
import Members from '../components/our-team/members';
import Advisors from '../components/our-team/advisors';

const YourResults = () => (
  <Layout>
    <SEO
      title='Your Results'
      description=''
    />
    <Hero />
    <Commitment />
    <Members />
    <Advisors />
  </Layout>
);

export default YourResults;
