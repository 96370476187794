import React from 'react';
import PropTypes from 'prop-types';

const Member = ({ name, title, image, body }) => (
  <div className='lg:w-1/3 pr-8 mb-4'>
    <img src={image} alt={name} className='flex-none mb-4 h-32' />

    <h4 className='font-sans font-semibold text-blue leading-snug mb-2'>
      {name}
    </h4>

    <h5 className='font-soft font-semibold text-xs uppercase text-black leading-snug mb-3'>
      {title}
    </h5>

    <p className='font-sans font-normal italic text-sm text-grey leading-snug'>
      {body}
    </p>
  </div>
);

Member.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  body: PropTypes.string,
};

Member.defaultProps = {
  name: '',
  title: '',
  image: '',
  body: '',
};

export default Member;
