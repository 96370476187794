import React from 'react';

const Commitment = () => (
  <div className='bg-white'>
    <div className='container mx-auto pt-16 px-8 flex flex-col lg:flex-row'>

      <div className='lg:w-3/4'>
        <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl mb-4'>
          Our Commitment
        </h2>

        <p className='font-sans font-normal text-lg text-grey leading-snug'>
          Ethics, privacy and security excellence is rooted in everything we do at Sequence Bio. We promise to be transparent about our information policies and practices, and to return benefits to Newfoundland and Labrador.
        </p>
      </div>

    </div>
  </div>
);

export default Commitment;
