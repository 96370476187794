import React from 'react';
import Member from './components/member';
import dennisOkeefe from '../../images/dennis-okeefe.png';
import gerryMugford from '../../images/gerry-mugford.png';
import fannyCoron from '../../images/fanny-coron.png';
import dianePower from '../../images/diane-power.png';

const Members = () => (
  <div className='container mx-auto px-8 my-16'>
    <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl mb-8'>
      Meet a few members of our research team!
    </h2>

    <div className='flex justify-between flex-col lg:flex-row mb-16 items-start'>
      <div className='flex flex-wrap flex-col lg:flex-row lg:w-3/4 mr-12'>
        <Member
          name='Dr. Dennis O’Keefe'
          title='Co-Principal Investigator'
          body='"Findings from the NL Genome Project may help participants and their physicians make more informed healthcare decisions."'
          image={dennisOkeefe}
        />

        <Member
          name='Dr. Gerald Mugford'
          title='Director of Research, Co-Principal Investigator'
          body='"This province can be a world leader in groundbreaking medical research. I believe the NL Genome Project is an important piece to realize this opportunity."'
          image={gerryMugford}
        />

        <Member
          name='Ms. Fanny Coron, MSc, ERGC'
          title='Genetic Counsellor'
          body='"Genetically, we’re all 99.9% the same. The NL Genome Project could help participants understand how that 0.01% makes them so unique."'
          image={fannyCoron}
        />

        <Member
          name='Ms. Diane Power, RN'
          title='Senior Manager, Clinical Operations'
          body='"Sequence Bio is committed to putting participants first, including returning findings and providing genetic counselling support to physicians."'
          image={dianePower}
        />
      </div>

      <div className='bg-grey-100 p-6 pb-8 lg:p-8 lg:pb-10 rounded lg:w-1/4 h-auto'>
        <h5 className='font-sans font-semibold text-black mb-2'>
          Join Our Team!
        </h5>

        <p className='font-sans text-sm text-black mb-4'>
          Sequence Bio is a group of motivated, entrepreneurial thinkers that are hoping to improve how medicines are developed and how patients are treated — all right here in Newfoundland and Labrador. Think you’ll fit right in, all while standing out? Get in touch today!
        </p>

        <a href='https://www.sequencebio.com/careers' className='font-sans font-bold text-sm text-blue pb-1 border-b-2 border-yellow hover:text-blue-900'>
          See our Current Openings
        </a>
      </div>
    </div>
  </div>
);

export default Members;
