import React from 'react';

const Hero = () => (
  <div className='team-hero'>
    <div className='container mx-auto pt-24 lg:pt-32 pb-16 px-8'>
      <div className='lg:w-2/5'>
        <h1 className='font-sans font-bold text-white text-4xl lg:text-5xl leading-very-tight mb-4'>
          Our Researchers and Team
        </h1>

        <p className='font-sans font-normal text-white mb-4 leading-snug'>
          Sequence Bio is a Newfoundland and Labrador biotechnology company striving to improve how we treat diseases and develop medicines. Headquartered in St. John’s and home to a passionate team, we truly believe that to be successful, we have to build a company that makes the entire province proud. And each and every one of us works hard to earn that trust day in and day out.
        </p>
      </div>
    </div>
  </div>
);

export default Hero;
