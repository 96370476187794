import React from 'react';
import Advisor from './components/advisor';
import euanAshley from '../../images/euan-ashley.png';
import pekLum from '../../images/pek-lum.png';

const Advisors = () => (
  <div className='container mx-auto px-8 my-16'>
    <h2 className='font-sans font-semibold text-blue text-xl lg:text-2xl mb-4'>
      Scientific Advisory Board
    </h2>

    <p className='font-sans font-normal text-grey leading-snug lg:w-2/3 mb-8'>
      We are guided by a world-class Scientific Advisory Board who provide strategic advice to Sequence Bio’s leadership regarding emerging science and technology, make recommendations regarding our research, and help develop opportunities to return results to participants and the healthcare system. We couldn’t be more proud to have them onboard.
    </p>

    <div className='flex flex-wrap flex-col lg:flex-row'>

      <Advisor
        name='Dr. Euan Ashley'
        title='Advisor'
        body='Dr. Euan Ashley is a renowned cardiologist and human genomics researcher. He led the team that carried out the first clinical interpretation of a human genome, and now routinely applies genome sequencing to the diagnosis of patients at Stanford Hospital where he also directs the Clinical Genome Service and the Center for Inherited Cardiovascular Disease. Dr. Ashley is Co-Founder of Personalis Inc., a genome scale genetic diagnostics company and a Co-Principal Investigator of the MyHeart Counts Study, a partner for Apple’s ResearchKit platform. He is a recipient of the National Innovation Award from the American Heart Association (AHA) and a National Institutes of Health (NIH) Director’s New Innovator Award. Dr. Ashley received his Ph.D. in Cardiovascular Science from the University of Oxford.'
        image={euanAshley}
      />

      <Advisor
        name='Dr. Pek Lum'
        title='Advisor'
        body='Dr. Pek Lum is a data scientist and systems biologist who has extensive experience with data-driven drug discovery, software development, and venture capital. She is CEO of Auransa Inc., a computational drug development company based in Palo Alto, California and previously held senior executive and scientific positions at Rosetta, Merck, iPierian and Ayasdi. Dr. Lum currently holds advisory positions with organizations such as Bayes Impact and The Michael J. Fox Foundation for Parkinson’s Research. Dr. Lum received her Ph.D. in Molecular Biology from the University of Washington.'
        image={pekLum}
      />
    </div>

  </div>
);

export default Advisors;
